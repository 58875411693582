<template>
  <form @submit.prevent="createTask" class="task-form">
    <div class="form-group">
      <label for="task-name">Task Name:</label>
      <input id="task-name" v-model="taskName" type="text" class="form-control" required />
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <textarea id="description" v-model="description" class="form-control"></textarea>
    </div>
    <button type="submit" class="submit-button">Create Task</button>
    <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


interface Organism {
  id: number;
  organism: string;
}

const props = defineProps<{
  selectedOrganisms: Organism[];
}>();

const emit = defineEmits(['taskCreated']);

const taskName = ref('');
const description = ref('');
const successMessage = ref<string | null>(null);
const errorMessage = ref<string | null>(null);
const router = useRouter();

async function createTask() {
  if (props.selectedOrganisms.length === 0) {
    errorMessage.value = 'Please select at least one organism.';
    return;
  }

  try {
    const response = await api.post('/api/organism_processing/tasks', {

      name: taskName.value,
      description: description.value,
      organism_ids: props.selectedOrganisms.map(org => org.id),
    });

    console.log('Task Created:', response.data);
    successMessage.value = 'Review your email for your task code, it will take a moment.';
    errorMessage.value = null;
    emit('taskCreated');
    
    // Optionally, reset the form
    taskName.value = '';
    description.value = '';
    router.push({ name: 'OrganismTaskList' });  // Redirect to task list page after a delay
  } catch (error) {
    console.error('Error creating task:', error);
    errorMessage.value = 'There was an error creating the task. Please try again later.';
  }
}
</script>

<style scoped>
.task-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.success-message, .error-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
</style>
