<template>
  <p class="selected-count" :class="{ selected: selectedCount > 0 }">Selected: {{ selectedCount }} organisms</p>
  <div class="organism-list">
    <ul v-if="!loading && organisms.length > 0">
      <li v-for="organism in paginatedOrganisms" :key="organism.id" class="organism-item">
        <label>
          <input
            type="checkbox"
            :value="organism"
            :checked="isSelected(organism)"
            @change="toggleSelection(organism)"
            class="checkbox"
          />
          <span class="organism-name">{{ organism.organism }}</span>
        </label>
      </li>
    </ul>
    <p v-if="loading" class="loading-message">Loading...</p>
    <p v-if="!loading && organisms.length === 0" class="empty-message">No organisms found.</p>
    <div v-if="totalPages > 1" class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
      <span class="pagination-current">{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';

const selectedCount = computed(() => {
  console.log("*COUNTING ORGANISMS*",(selectedCount));
  return props.selectedOrganisms.length;
});

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

interface Organism {
  id: number;
  organism: string;
}

const props = defineProps<{
  selectedOrganisms: Organism[];
}>();

const emit = defineEmits(['update:selectedOrganisms']);

const organisms = ref<Organism[]>([]);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = 5; // Set page size

const totalPages = computed(() => Math.ceil(organisms.value.length / pageSize));

const paginatedOrganisms = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  return organisms.value.slice(start, start + pageSize);
});

async function fetchOrganisms() {
  loading.value = true;
  try {
    const response = await api.get('/api/organisms/organisms');
    organisms.value = response.data;
  } catch (error) {
    console.error('Error fetching organisms:', error);
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}

function isSelected(organism: Organism): boolean {
  return props.selectedOrganisms.some(selected => selected.id === organism.id);
}

function toggleSelection(organism: Organism) {
  let newSelection;
  if (isSelected(organism)) {
    console.log("selectedOrganisms after update:", props.selectedOrganisms);
    newSelection = props.selectedOrganisms.filter(selected => selected.id !== organism.id);
  } else {
    newSelection = [...props.selectedOrganisms, organism];
  }
  emit('update:selectedOrganisms', newSelection);
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

onMounted(() => {
  fetchOrganisms();
});
</script>

<style scoped>
.selected-count {
  font-weight: bold;
  margin-bottom: 10px;
  color: #ccc;
}

.selected-count.selected {
  color: #000000; /* Color when at least one element is selected */
}

.organism-list {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  padding: 20px;

}

.organism-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}



.organism-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.organism-item:last-child {
  border-bottom: none;
}

.checkbox {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.organism-name {
  font-size: 16px;
  color: #333;
}

.loading-message,
.empty-message {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-top: 20px;
}

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}
</style>
