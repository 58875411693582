<template>
    <div class="create-organism-page">
      <h2>Create New Organism</h2>
      <form @submit.prevent="createOrganism">
        <div class="organism-group">
          <label for="organism-name">Organism Name: </label>
          <input id="organism-name" class="form-control" v-model="organismName" type="text" required />
        </div>
        <div class="organism-group">
          <label for="condition">Condition: </label>
          <input id="condition" class="form-control" v-model="condition" type="text" required />
        </div>
        <div>
          <label for="zip-file">Upload ZIP File:</label> <strong>(20mb)</strong>
          <input id="zip-file" type="file" @change="handleFileUpload" accept=".zip" required />
        </div>
        <button type="submit" :disabled="isUploading">
        <span v-if="!isUploading">Publish Organism</span>
        <span v-else>
          <template v-if="uploadProgress < 100">
            Uploading... <progress :value="uploadProgress" max="100"></progress>
          </template>
          <template v-else>
            Upload Successful!
          </template>
        </span>
      </button>
      </form>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';

//*
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const token = authStore.token;
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization':`Bearer ${token}`
  },
  });
// Set the Authorization header for all requests if token is available


  const organismName = ref('');
  const condition = ref('');
  const zipFile = ref<File | null>(null); // Holds the uploaded ZIP file
  
  const router = useRouter();
  
  function handleFileUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      zipFile.value = target.files[0];
      console.log('File selected:', zipFile.value.name);
    }
  }
  
  const uploadProgress = ref(0); // Track upload progress
  const isUploading = ref(false); // Flag for upload state

  async function createOrganism() {
    if (!zipFile.value) {
      alert('Please upload a ZIP file.');
      return;
    }
    
    isUploading.value = true; // Set flag to disable button and show progress

    try {

      // Send the data to the server using axios

      const formData = new FormData();
      formData.append('organism', organismName.value);
      formData.append('condition', condition.value);
      formData.append('zip_file', zipFile.value);  // zip_file como archivo

      console.log("Sending",formData);
      const response = await api.post('/api/organisms/organisms', formData, {
        onUploadProgress: (progressEvent) => {
        uploadProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
      });
  
      console.log('Organism Created:', response.data);
  
      isUploading.value = false; // Reset flag after successful upload

      // After successful creation, redirect back to the list page
      router.push({ name: 'OrganismList' });
    } catch (error) {
      console.error('Error creating organism:', error);

      isUploading.value = false; // Reset flag in case of error

      alert('There was an error creating the organism.');
    }
  }
  </script>
  <style scoped>
  .create-organism-page {
    padding: 16px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .organism-group {
    display: flex;
    flex-direction: column;
  }

  .form-control:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }

  label {
    margin-bottom: 5px;
  }

  input {
    width: 350px; /* Adjust the width as needed */
    padding: 5px; /* Adjust the padding as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  #zip-file {
    display: flex;
    flex-direction: column;
    border: none;
  }

  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  button:hover {
    background-color: #0056b3;
  }


  button {
  /* ... existing styles ... */
  transition: background-color 0.2s ease-in-out;
}

button[disabled] {
  background-color: #ccc;
  cursor: default;
}

progress {
  /* Customize progress bar appearance here (optional) */
  width: 100%; /* Adjust width as needed */
  height: 5px; /* Adjust height as needed */
  background-color: #ddd;
  border: none;
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background-color: #007bff; /* Adjust progress bar color here (optional) */
}
  </style>
  