import { defineStore } from 'pinia';
import axios, { AxiosInstance } from 'axios';

interface AuthState {
    token: string | null;
    username: string | null;
    password: string | null;
}

async function obtenerToken(api:AxiosInstance, username: string | null , password: string | null) {
    try {
        console.log(username)
        console.log(password)
      const response = await api.post('/api/login', {

        username: username,
        password: password,
      });
  
      return response.data.token; // Suponiendo que el token se devuelve en la propiedad 'token' de la respuesta
    } catch (error) {
      console.error('Error al obtener el token:', error);
      throw error; // Propagar el error para que sea manejado en otro lugar
    }
  }

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        token: localStorage.getItem('authToken'),
        username: localStorage.getItem('username'),
        password: null
    }),
    actions: {
        async login(api:AxiosInstance, username: string, password: string): Promise<void> {
            // Simulate an API call
            console.log("USER",username)
            console.log("PASS",password)
            if (username != '' && password != '') {
                // crear llamada a axios.post login
                const token = await obtenerToken(api, username, password);
            console.log('EL TOKEN ES => ',(token))

                //Terminando llamada a axios.post login

    
                this.token = token;
                this.username = username;
                localStorage.setItem('authToken', token);
                localStorage.setItem('user', username);
            } else {
                throw new Error('Invalid credentials');
            }
        },
        logout() {
            this.token = null;
            this.username = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('username');
        },
    },
    getters: {
        isAuthenticated: (state): boolean => !!state.token,
    },
});
