<template>
  <div class="container">
    <div class="content-wrapper">
      <p class="welcome-msg">Welcome, <strong> {{ userDetails.username }}</strong>.</p>
      <div class="details-container">
        <ul v-if="userDetails" >
            <div class="details-list">
                <div class="icon-container">
                <font-awesome-icon icon="user" class="icons-details" />
                <p><strong>Personal Data</strong></p>
              </div>
              <li>First Name: {{ userDetails.name }}</li>
              <li>Last Name: {{ userDetails.last_name }}</li>
              <div class="icon-container">
                <font-awesome-icon icon="address-book" class="icons-details" />
                <p><strong>Contact Details</strong></p>
              </div>
              <li>Email:  {{ userDetails.email }}</li>
              <li>Phone: {{userDetails.phone_number}}</li>
            </div>
        </ul>
      </div>
      <div class="button-container">
        <button @click="fetchUserDetails" v-if="!userDetails">Cargar Detalles</button>
        <button class="logout" @click="logout">Logout</button>
        <router-link to="/change-password" class="logout change-pass">Change Password</router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';
import ChangePassword from './ChangePassword.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    FontAwesomeIcon, // Registrar el componente
    ChangePassword,
  },
  setup() {
    const userDetails = ref(null);
    console.log("AuthSTORE->")
    const configStore = useConfigStore();
    const authStore = useAuthStore();
    const api = axios.create({
      baseURL: configStore.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const username = ref(authStore.username);

    const fetchUserDetails = async () => {
      try {
        const response = await api.get('/api/me', {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        userDetails.value = response.data;
      } catch (error) {
        console.error('Error al obtener los detalles del usuario:', error);
      }
    };

    // Llamar a fetchUserDetails si el token ya existe
    if (authStore.token) {
      fetchUserDetails();
    }

    const logout = () => {
      authStore.logout();
    };

    return {
      username,
      userDetails,
      fetchUserDetails,
      logout,
    };
  },
});
</script>
<style scoped>
.welcome-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007bff;
  color: #ffffff;
  font-size: 20px;
  border-radius: 4px;
}
.container {
  display: flex;
  justify-content: center; /* Aligns content to the right */
  align-items: center;
}

.content-wrapper {
  display: flex; /* Make content a flexbox container */
  flex-direction: column; /* Stack content vertically */
  background: #e3f1ff;
  padding: 50px;
  margin-top: 4em;
  border-radius: 4px;
}

.details-container ul {
  list-style: none;
  padding: 0;
}

.details-list li{
 margin-bottom: 10px;
}

.details-list li::before {
  content: "✓";
  color: green; /* Cambia el color de la viñeta */
  font-size: 18px; /* Ajusta el tamaño de la viñeta */
  margin-right: 5px; /* Ajusta el espacio entre la viñeta y el texto */
}

.icon-container {
  display: flex;
  align-items: center;
  padding: 10px;
}
.icon-container > p {
 margin: 10px;
}

.icons-details {
  font-size: 23px;
  color: #007bff;
}

.logout {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.logout:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Esto distribuye los elementos de forma equitativa */
}
</style>
