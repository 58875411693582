import { defineStore } from 'pinia';

export const useConfigStore = defineStore('config', {
  state: () => {
    return {
      baseUrl: import.meta.env.VITE_APP_BASE_URL || 'localhost:8000', // Load the environment variable here
    };
  },
  actions: {
    setBaseUrl(url: string) {
      this.baseUrl = url;
    },
  },
});