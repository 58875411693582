import { createRouter, createWebHistory } from 'vue-router';
import Login from '@components/Login.vue';
import Register from '@components/Register.vue';
import Dashboard from '@components/Dashboard.vue';
import OrganismTaskPage from '@components/OrganismTaskPage.vue'; 
import OrganismTaskListPage from '@components/OrganismTaskListPage.vue'; // Adjust the path as necessary
import OrganismListPage from '@components/OrganismListPage.vue'; 
import { useAuthStore } from '@stores/authStore';
import CreateOrganismPage from '@components/CreateOrganismPage.vue'; // Adjust the path as necessary
import Profile from '@components/profile.vue';
import ChangePassword from '@components/ChangePassword.vue';


const routes = [
    {
        path: '/',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    }, 
    {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                next('/');
            } else {
                next();
            }
        },
    },
    {
        path:'/organism-task',
        name: 'OrganismTask',
        component: OrganismTaskPage
    },
    {
        path: '/organism-task-list',
        name: 'OrganismTaskList',
        component: OrganismTaskListPage,
      },    
      {
        path: '/organism-list',
        name: 'OrganismList',
        component: OrganismListPage,
      },        
      {
        path: '/create-organism',
        name: 'CreateOrganism',
        component: CreateOrganismPage,
      }, 
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
      } // Add this route for ChangePassword     
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
