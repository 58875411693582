<template>
  <div class="login-container">
    <h2>Login Pheflux</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="username">Username:</label>
        <input id="username" v-model="username" type="text" class="form-control" placeholder="Enter your username" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input id="password" v-model="password" type="password" class="form-control" placeholder="Enter your password" required />
      </div>
      <div class="action-group">
        <button type="submit" class="login-button">Login</button>
        <a @click="goToRegisterPage" href="#" class="register-link">Register</a>
      </div>
      <p v-if="error" class="error-message">{{ error }}</p>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useAuthStore } from '@stores/authStore';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@stores/configStore';
import axios from 'axios';

export default defineComponent({
  setup() {
    const username = ref('');
    const password = ref('');
    const error = ref('');
    const router = useRouter();

    const configStore = useConfigStore();
    const authStore = useAuthStore();
    const api = axios.create({
      baseURL: configStore.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      });
    console.log("Baseurl", )


    const handleLogin = async () => {
      try {
        await authStore.login(api, username.value, password.value);
        router.push('/dashboard'); // Redirect to dashboard after login
      } catch (err) {
        error.value = 'Login failed. Please check your credentials.';
      }
    };

    const goToRegisterPage = () => {
      router.push('/register'); // Navigate to the registration page
    };

    return {
      username,
      password,
      error,
      handleLogin,
      goToRegisterPage,
    };
  },
});
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.login-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.register-link {
  color: #007bff;
  text-decoration: none;
}

.register-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 16px;
  text-align: center;
}
</style>
