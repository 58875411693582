<template>
    <div class="organism-list-page">
      <div class="search-organisms">
      <label>Search:</label>
      <input type="text" placeholder="Search Organisms..." class="search-input">
      </div>
      <h2>Organism List</h2>
      <button @click="goToCreatePage" class="create-button">Create New Organism</button>
      <table class="organism-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Organism Name</th>
            <th>Condition</th>
            <th>Date Created</th>
            <th>Valid</th>
            <!--<th>Published By</th>-->

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="7">Loading...</td>
          </tr>
          <tr v-if="!loading && organisms.length === 0">
            <td colspan="7">No organisms found.</td>
          </tr>
          <tr v-for="organism in paginatedOrganisms" :key="organism.id" class="organism-row">
            <td>{{ organism.id }}</td>
            <td>{{ organism.organism }}</td>
            <td>{{ organism.condition }}</td>
            <td>{{ organism.date_creation }}</td>
            <td>{{ organism.valid ? 'Yes' : 'No' }}</td>
           <!--<td>{{ organism.user.username }}</td>--> 

            <td>
              <a @click.prevent="editOrganism(organism.id)" href="#" class="action-link">Edit</a> |
              <a @click.prevent="deleteOrganism(organism.id)" href="#" class="action-link">Delete</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
        <span class="pagination-current">{{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { useAuthStore } from '@stores/authStore';
  import { useConfigStore } from '@stores/configStore';

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });

console.log("URL",configStore.baseUrl)


  interface User {
    username: string;
  }
  
  interface Organism {
    id: number;
    organism: string;
    condition: string;
    date_creation: string;
    valid: boolean;
    user: User;
  }
  
  const organisms = ref<Organism[]>([]);
  const loading = ref(false);
  const currentPage = ref(1);
  const pageSize = 5; // Set page size
  const totalPages = computed(() => Math.ceil(organisms.value.length / pageSize));
  
  const paginatedOrganisms = computed(() => {
    const start = (currentPage.value - 1) * pageSize;
    return organisms.value.slice(start, start + pageSize);
  });
  
  const router = useRouter();

  // Set the Authorization header for all requests if token is available
  api.interceptors.request.use((config) => {
    const token = authStore.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  async function fetchOrganisms() {
    console.log("INICIANDO FETCH DE ORGANISMS")
    loading.value = true;
    try {
      console.log("LLAMADA A ENDPOINT ORGANISMS/LIST", api)
      const response = await api.get('/api/organisms/organisms');
      organisms.value = response.data;
      console.log("RESULTADOS",(response))

    } catch (error) {
      console.error('Error fetching organisms:', error);
      alert('There was an error fetching the organisms.');
    } finally {
      loading.value = false;
    }
  }
  
  function prevPage() {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  }
  
  function nextPage() {
    if (currentPage.value < totalPages.value) {
      currentPage.value++;
    }
  }
  
  function editOrganism(id: number) {
    console.log(`Edit organism with ID: ${id}`);
  }
  
  function deleteOrganism(id: number) {
    organisms.value = organisms.value.filter((organism) => organism.id !== id);
    console.log(`Delete organism with ID: ${id}`);
  }
  
  function goToCreatePage() {
    router.push({ name: 'CreateOrganism' });
  }
  
  // Fetch organisms when the component is mounted
  onMounted(() => {
    fetchOrganisms();
  });
  </script>
  
  <style scoped>
  .search-organisms {
    display: flex;
    align-items: center;
  }

  .search-organisms label {
    margin-right: 10px;
  }
  .search-input {
   
    width: 30%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

  .organism-list-page {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
  }
  
  .create-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .create-button:hover {
    background-color: #0056b3;
  }
  
  .organism-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .organism-table th,
  .organism-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 16px;
  }
  
  .organism-table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .organism-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .organism-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .action-link {
    color: #3498db;
    text-decoration: none;
    cursor: pointer;
  }
  
  .action-link:hover {
    text-decoration: underline;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  
  .pagination-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 16px;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-current {
    font-size: 16px;
    font-weight: bold;
  }
  </style>
  