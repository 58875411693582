<template>
  <div class="organism-task-page">
    <div class="left-panel">
      <h2>Select Organisms</h2>
      <organism-list
        :organisms="organisms"
        v-model:selectedOrganisms="selectedOrganisms"
      />
    </div>
    <div class="right-panel">
      <h2>Create New Task</h2>
      <create-task
        :selectedOrganisms="selectedOrganisms"
        @taskCreated="handleTaskCreated"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import OrganismList from './OrganismList.vue';
import CreateTask from './CreateTask.vue';

interface Organism {
  id: number;
  organism: string;
}

const organisms = ref<Organism[]>([]);
const selectedOrganisms = ref<Organism[]>([]);

async function fetchOrganisms() {
  try {
    const response = await axios.get('/api/organisms/organisms');
    organisms.value = response.data;
  } catch (error) {
    console.error('Error fetching organisms:', error);
  }
}

function handleTaskCreated() {
  // After task is created, you can reset the selection or redirect the user
  selectedOrganisms.value = [];
}

onMounted(() => {
  fetchOrganisms();
});
</script>

<style scoped>
.organism-task-page {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.left-panel {
  width: 45%;
  border-right: 1px solid #ddd;
  padding-right: 16px;
}

.right-panel {
  width: 55%;
  padding-left: 16px;
}

.task-form {
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
</style>
