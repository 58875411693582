import { createApp } from 'vue'
import { createPinia } from 'pinia';
import './style.css';
import App from './App.vue';
import router from './router';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faUser, faAddressBook } from '@fortawesome/free-solid-svg-icons';
//******* 

// Add the icons to the library
library.add(faCoffee);
library.add(faUser, faAddressBook);
//*******

const pinia = createPinia();

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon); //font awesome

app.use(pinia);
app.use(router);
app.mount('#app');
