<template>
  <div id="app">
    <nav>
      <router-link to="/" active-class="active" >Home</router-link> |
      <router-link to="/organism-list" active-class="active" >Organisms</router-link> |
      <router-link to="/organism-task" active-class="active" >Organism Task Page</router-link> |
      <router-link to="/organism-task-list" active-class="active" >Organism Task List</router-link> |
      <router-link to="/dashboard" active-class="active" >Your profile</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>
<script setup lang="ts">
import Form from './components/Form.vue';
import Home from './components/Home.vue';
/*import Manual from './components/Manual.vue';
import Contact from './components/Contact.vue';
import Organism from './components/Organism.vue';*/
import Profile from './components/Profile.vue;'


const baseUrl = import.meta.env.VITE_APP_BASE_URL;
console.log("Checking baseUrl")
console.log(baseUrl); // Outputs: https://example.com

</script>
<style scoped>
/* Styles for the navigation element and its links */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #e3f1ff;
  color: #fff;
}

nav > a { /*Aporte raql: Selector específico que ayuda a que el estilo se aplique solo al elemento deseado*/ 
  color: #000000;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  transition: all 0.2s ease-in-out; /* Add transition for shadow */
}

nav a:hover {
  text-decoration: none;
  text-shadow: 4px 2px 10px rgba(0, 0, 0, 0.2); /* Blurred shadow */
}

nav a.active {  /* Styles for the active link */
  background-color: #007bff; /* Adjust background color as desired */
  color: #fff;
  text-shadow: none;
  text-decoration: none;
}

nav a:active, nav a:focus {
  text-decoration: none;
  text-shadow: 0px 2px 5px rgba(33, 39, 46, 0.2);
}

nav a:focus {
  outline: none; /* Remove default outline */
}
</style>
