<template>
  <textarea readonly cols="40" rows="10">
    TÉRMINOS Y CONDICIONES DE USO

    {{ terms }}

    <!-- contenido de los términos y condiciones -->
  </textarea>
</template>

<script lang="ts">
import { ref } from 'vue';
import termsJson from './terminos.json';

export default {
  setup() {
    const terms = ref(termsJson.terms);

    return {
      terms,
    };
  },
};
</script>

<style scoped>
/* Styling for the textarea */
textarea {
  width: 100%;
  height: 200px;
  resize: none;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
